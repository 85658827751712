<template>
  <p v-if="!records.length" class="mt-4 mb-8 no-data">
    Geen ritten gevonden
  </p>
  <div v-else class="w-full overflow-x-scroll">
    <div class="card flex flex-col gap-2 my-4">
      <ul class="text-sm mb-4">
        <li><strong>1. Prijs rit</strong> (groeter) uitbetaling chauffeur per uur</li>
        <li><strong>2. Omzet chauffeur</strong> (groeter) uitbetaling chauffeur per uur</li>
        <li><strong>3. Loon chauffeur</strong> (groeter) uitbetaling chauffeur per uur</li>
      </ul>
      <small>Indien laatste (3de) status een ✅ is, betekend dit dat het voordeliger is per uur te betalen</small>
      <small>Indien laatste (3de) status een ❌ is, betekend dit dat het voordeliger is om op % te werken</small>
    </div>

    <UIButtonCompact v-model="compact" class="small my-2" />

    <table class="w-full custom-bordered">
      <thead>
        <tr class="whitespace-nowrap text-left">
          <th class="text-center">
            Rit
          </th>
          <th class="text-center">
            Totaalprijs
          </th>
          <th class="text-center">
            Gereden uren
          </th>
          <th class="text-center">
            Omzet chauffeur
          </th>
          <th class="text-center">
            % Loon
          </th>
          <th class="text-center">
            Loon
          </th>
          <th class="text-center">
            loon/uur
          </th>
          <th class="text-center">
            &euro;13/uur
          </th>
          <th class="text-center">
            &euro;25/uur
          </th>
          <th class="text-center">
            Kilometers
          </th>
          <th v-if="!compact">
            Gegevens
          </th>
          <th v-if="!compact">
            Adressen
          </th>
        </tr>
      </thead>
      <tbody class="whitespace-nowrap">
        <tr
          v-for="(record, recordIndex) in records"
          :key="`${record.uuid}${record.rit?.id}${recordIndex}`"
          class="w-full grid lg:table-row align-top mb-8 card"
        >
          <td class="text-center">
            <router-link
              :to="`/ritten/info/${record.rit.id}`"
              class="btn bg-blue-500 text-white h-8"
            >
              <i class="fas fa-info-circle"></i> {{ record.rit.id }}
            </router-link>
          </td>
          <td class="card text-center">
            &euro;&nbsp;{{ formatPrice(record.rit.prijs) }}
          </td>
          <td v-if="record.chauffeurData == null" class="card text-center text-red-500 italic" colspan="8">
            Niet alle chauffeur data beschikbaar
          </td>
          <template v-else>
            <td class="card text-center">
              {{ secondsToHMSWritten(record.chauffeurData?.gereden_seconden) }}
              <small v-if="record.chauffeurData.gereden_seconden <= 0" class="block text-red-500 italic">
                Missend tijd! Verkeerd ingevulde shiften!
              </small>
              <small v-else-if="record.chauffeurData.shiften_verkeerd > 0" class="block text-red-500 italic">
                {{ record.chauffeurData.shiften_verkeerd }} Verkeerd ingevulde shiften!
              </small>
            </td>
            <td class="card text-center">
              &euro;&nbsp;{{ formatPrice(record.chauffeurData?.omzet) }}
            </td>
            <td class="card text-center">
              {{ formatPrice(record.chauffeurData?.loon / record.chauffeurData?.omzet * 100) }} %
            </td>
            <td class="card text-center">
              &euro;&nbsp;{{ formatPrice(record.chauffeurData?.loon) }}
            </td>
            <td class="card text-center">
              &euro;&nbsp;{{ formatPrice(record.chauffeurData?.loon_per_uur) }}
            </td>
            <td class="card text-center">
              {{ record.rendabiliteit.prijs_13 ? '✅' : '❌' }}
              {{ record.rendabiliteit.omzet_13 ? '✅' : '❌' }}
              {{ record.rendabiliteit.loon_13 ? '✅' : '❌' }}
              &euro;&nbsp;{{ formatPrice(record.chauffeurData?.uitbetaling_13_per_uur) }}
            </td>
            <td class="card text-center">
              {{ record.rendabiliteit.prijs_25 ? '✅' : '❌' }}
              {{ record.rendabiliteit.omzet_25 ? '✅' : '❌' }}
              {{ record.rendabiliteit.loon_25 ? '✅' : '❌' }}
              &euro;&nbsp;{{ formatPrice(record.chauffeurData?.uitbetaling_25_per_uur) }}
            </td>
            <td class="card text-center">
              {{ record.rit.kilometers_heen + record.rit.kilometers_retour }} Km.
            </td>
          </template>

          <td v-if="!compact" class="card">
            <div class="flex flex-col gap-2 my-2">
              <KlantLijn class="card flex-col gray" label="Klant" :klant="record.klant || { firstname: '?' }" />
              <KlantLijn
                v-if="record.reiziger"
                class="card flex-col gray"
                label="Reiziger"
                :klant="record.reiziger || { firstname: '?' }"
              />
              <span v-if="record.rit.is_factuur_nodig && !record.facturatie" class="card flex-col rounded">
                <strong>Facturatie:</strong> <span class="text-yellow-500">Factuur nodig, maar geen facturatie gegevens</span>
              </span>
              <FacturatieLijn
                v-else-if="record.facturatie"
                class="card flex-col gray"
                label="Facturatie"
                :facturatie="record.facturatie"
              />
            </div>
          </td>

          <td v-if="!compact" class="card">
            <div class="flex flex-col card rounded gray">
              <div>
                <strong>Heenreis: </strong>
                <router-link class="hover:underline" target="_blank" :to="{ name: 'Kalender', query: { date: dateString(record.rit.vertrek_datetime), rit_id: record.rit.id, retour: false } }">
                  {{ dateTimeStringLocale(record.rit.vertrek_datetime) }}
                </router-link>
              </div>
              <GoogleAdressenVisueel class="card gray pr-4 my-1" :adressen="record.adressen_heen" />
            </div>

            <div v-if="record.rit.is_retour" class="flex flex-col card rounded gray">
              <div>
                <strong>Retourreis: </strong>
                <router-link class="hover:underline" target="_blank" :to="{ name: 'Kalender', query: { date: dateString(record.rit.retour_datetime), rit_id: record.rit.id, retour: true } }">
                  {{ dateTimeStringLocale(record.rit.retour_datetime) }}
                </router-link>
              </div>
              <GoogleAdressenVisueel class="card gray pr-4 my-1" :adressen="record.adressen_retour" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, defineProps, ref, toRefs } from 'vue'

import UIButtonCompact from '@/components/UI/Button/Compact.vue'

import { dateString, dateTimeStringLocale, secondsToHMSWritten } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'

const props = defineProps({
  list: {
    type: Array,
    default() {
      return []
    },
  },
})
const KlantLijn = defineAsyncComponent(() => import('@/components/Table/Planning/KlantLijn.vue'))
const FacturatieLijn = defineAsyncComponent(() => import('@/components/Table/Planning/FacturatieLijn.vue'))
const GoogleAdressenVisueel = defineAsyncComponent(() => import('@/components/Google/AdressenVisueel.vue'))

const { list } = toRefs(props)
const compact = ref(true)

const chauffeurShiftenNietIngevuld = chauffeurRecord => !chauffeurRecord.shift_start || !chauffeurRecord.shift_einde
const chauffeurGeredenSeconden = chauffeurRecord => Math.round((chauffeurRecord.shift_start && chauffeurRecord.shift_einde ? new Date(chauffeurRecord.shift_einde).getTime() - new Date(chauffeurRecord.shift_start).getTime() : 0) / 1000)
const chauffeurOmzet = chauffeurRecord => Math.max(0, chauffeurRecord.omzet)
const chauffeurLoon = chauffeurRecord => Math.max(0, chauffeurRecord.omzet * (chauffeurRecord.commisie / 100))

const records = computed(() => {
  return list.value.map(record => {
    if (!record.chauffeurs_heen?.length || (record.rit.is_retour && !record.chauffeurs_retour?.length)) {
      return {
        ...record,
        chauffeurData: null,
      }
    }

    const chauffeurData = {
      aantal: 0,
      omzet: 0,
      gereden_seconden: 0,
      loon: 0,
      loon_per_uur: 0,
      uitbetaling_13_per_uur: 0,
      uitbetaling_25_per_uur: 0,
    }

    const chauffeurs = [].concat(record.chauffeurs_heen, record.chauffeurs_retour)

    chauffeurData.aantal = chauffeurs.length
    chauffeurData.shiften_verkeerd = chauffeurs.filter(chauffeurShiftenNietIngevuld).length
    chauffeurData.omzet = chauffeurs.reduce((acc, chauffeur) => acc + chauffeurOmzet(chauffeur), 0)
    chauffeurData.gereden_seconden = chauffeurs.reduce((acc, chauffeur) => acc + chauffeurGeredenSeconden(chauffeur), 0)
    const gereden_uren = (chauffeurData.gereden_seconden / 3600)
    chauffeurData.loon = chauffeurs.reduce((acc, chauffeur) => acc + chauffeurLoon(chauffeur), 0)
    chauffeurData.loon_per_uur = chauffeurData.loon / gereden_uren
    chauffeurData.uitbetaling_13_per_uur = gereden_uren * 13
    chauffeurData.uitbetaling_25_per_uur = gereden_uren * 25

    const result = {
      ...record,
      chauffeurData,
      rendabiliteit: {
        prijs_13: record.rit.prijs > chauffeurData.uitbetaling_13_per_uur,
        omzet_13: chauffeurData.omzet > chauffeurData.uitbetaling_13_per_uur,
        loon_13: chauffeurData.loon > chauffeurData.uitbetaling_13_per_uur,
        prijs_25: record.rit.prijs > chauffeurData.uitbetaling_25_per_uur,
        omzet_25: chauffeurData.omzet > chauffeurData.uitbetaling_25_per_uur,
        loon_25: chauffeurData.loon > chauffeurData.uitbetaling_25_per_uur,
      },
    }

    delete result.chauffeurs_heen
    delete result.chauffeurs_retour

    return result
  })
})
</script>
